import {createRouter, createWebHistory} from 'vue-router'
import { nextTick } from 'vue'

// the following three aren't loaded dynamically so we can speed up their page loads
  // otherwise there is an ocassional slight delay when you switch to the components
import Home from '@/views/home/Home.vue';

const Error404 = () => import('@/views/Error404.vue');


const router = createRouter({
  // web history requires some schenanighans in the backend to get things to serve correctly
  history: createWebHistory(),
  routes: [
    { 
      name: "App",
      path: '/', 
      redirect: '/home',
    },
    { 
      name: "Home",
      path: '/home',
      component: Home,
      children: [
        {
          name: "❔❔❔",
          path: "/home/not-found",
          component: Error404
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*', 
      redirect: '/home/not-found'
    }
  ], // short for `routes: routes`
})


/**
 * Important to update the page title AFTER the page is loaded. Otherwise we update the page title of the last page, and then 
 * the whole history is off. 
 */
router.afterEach((route) => {
  /**
   * We have default page titles for all pages, and the page title is no longer updated after the first call. 
   */
  if(route && route.meta?.title) {
    setPageTitle(route.meta.title);
  } else {
    setPageTitle(route.name);
  }
})

router.onError((error) => {
  if (error.type === 'chunkLoadError') {
    if(navigator.onLine) {
      // Get the last refresh time from local storage
      const lastRefresh = localStorage.getItem('lastRefresh')
      
      // Check if the last refresh was more than 5 minutes ago
      if (!lastRefresh || (new Date() - new Date(lastRefresh)) > 5 * 60 * 1000) {
        localStorage.setItem('lastRefresh', new Date())
        location.reload()
      } else {
        console.log('Page was recently refreshed, please check for updates later')
      }
    } else {
      console.log('Browser is offline, please check your internet connection')
    }
  }
})

// router.resolve({
//   name: 'not-found',
//   params: { pathMatch: ['not', 'found'] },
// }).href // '/not/found'

export default router;

/**
 * Set's the title of the page, and appends the current site name, like so: 'pageTitle | Website Title'
 * Make's it simple to handle page titles, just call this function after the page itself has handled the tricky dynamic loading. 
 * 
 * @param {*} pageTitle 
 */
export const setPageTitle = function (pageTitle) {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    console.log('setting page title', pageTitle);
    // we set the name of the route to the new page title, so when we navigate back it has the last set page title. 
    // router.currentRoute.name = pageTitle;
    document.title = pageTitle + ' | Brendan Moore';
  });
}
