<template>
  <div style="position: relative;"> 

    <div class="background" v-if="backgroundEnabled != INACTIVE">
      <HTMLBlockTransitions></HTMLBlockTransitions>
      <!-- <WebGLPixels></WebGLPixels> -->
    </div>

    <div class="foreground">
      <div class="settings-container hidden">
        
        <v-btn class="glass " icon size="x-large" >
          <x-icon color="white" :iconPath="mdiCog" :scale="2"></x-icon>
        </v-btn>
      </div>

      <!-- Child is injected here and given a max-width -->
      <slot></slot>
      <div class="filler"></div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from 'vue';


import HTMLBlockTransitions from '@/components/backgrounds/html-block-transitions.vue';
import WebGLPixels from '@/components/backgrounds/webgl-pixels.vue';

import Core from '@/utils/core';

import XIcon from './_generics/x-icon.vue';

import { mdiCog } from '@mdi/js';

const AppBackground = defineComponent({

  components: {
    HTMLBlockTransitions,
    WebGLPixels,
    XIcon,
  },

  data(){
    const INACTIVE = 0, ACTIVE = 1;
 
    return {
      ACTIVE, INACTIVE,

      mdiCog,

      backgroundEnabled: Core.Config.getSetting('bg_state', ACTIVE),
    }
  },

  mounted(){

  }
});

export default AppBackground;

</script>

<style lang="scss">

  .background {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;

    filter: blur(40px);
  }

  .foreground {
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 0 20px;

    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.2);


    /* backdrop-filter: blur(80px); */

    /* why can't safari just accept the regular property... */
    /* -webkit-backdrop-filter: blur(40px); */
  }

  .settings-container {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .filler {
    flex-grow: 1;
  }

  @media only screen and (max-width: $SCREEN_MEDIUM) {
    .background {
      // display: none;
    }
  }

  @media only screen and (max-width: $SCREEN_SMALL) {
    .filler {
      display: none;
    }
  }

</style>