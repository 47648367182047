<template>
  <div class="fill-height fill-width d-flex align-center justify-center pa-4">
    <iframe width="780" height="500" src="https://www.youtube.com/embed/39lPHHvkzYg" frameborder="0" allowfullscreen></iframe>
    <div class="ian" :src="ianImgSrc" alt="Ian" ></div>
  </div>
</template>


<script>
import { defineComponent } from "vue";
import IAN from '@/assets/images/IAN.jpg';

const Home = defineComponent({

  inject: ['appStore'],

  components: {
  },  

  mounted(){
  },

  data: function() {
  },

  computed: {
    ianImgSrc() {
      return IAN;
    }
  },

  methods: {

  }
});

export default Home;

</script>

<style scoped>
  .ian {
    position: fixed;
    top: 0; 
    right: 0;
    margin: 1.6em;

    border-radius: 50%;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);

    max-width: 100px;
    max-height: 100px;
    /* constrain to width of screen, auto adjust. Must be square aspect ratio */
    width: 100vw;
    height: 100vw;



    background-image: url('@/assets/images/IAN.jpg');
    background-size: cover;
  }
</style>
