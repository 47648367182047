const Config = {
  
  /**
   * Gets a setting from localStorage (maybe the network at some point)
   * 
   * @param {string} key The key of the setting. 
   * @param {any} defaultValue The default value in case the setting isn't set. 
   * @returns {string} The item from the local storage, might be 
   */
  getSetting(key, defaultValue=null){
    const setting = localStorage.getItem(key);
    const finalSetting = setting != null ? setting : defaultValue;

    // console.log('finalSetting', finalSetting);

    return finalSetting;
  },

  /**
   * Sets a setting for locally (and maybe the network at some point)
   * 
   */
  setSetting(key, value){
    localStorage.setItem(key, value);
  }
}

export default Config;