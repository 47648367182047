import { reactive, computed } from 'vue'

const INITIAL_STATE = {
  pageContainerPadding: "20px 40px",
};

const initAppStore = () => {
  const state = reactive(Object.assign({}, INITIAL_STATE))

  /** */
  const resetPagePadding = () => {
    state.pageContainerPadding = INITIAL_STATE.pageContainerPadding;
  }

  /** 
   * Useful for apps / tools that should be scaled to the full page width. 
   */
  const disablePagePadding = () => {
    state.pageContainerPadding = "0";
  }

  return {
    state,
    resetPagePadding,
    disablePagePadding,
  }
}

const appStore = initAppStore();

export default appStore;