<template>
  <div 
    v-bind="$attrs"
    ref="expandContainer"
    :style="`--expand-container-transition-time: ${transitionTime}s; height: ${hasLoaded ? `${this.height}px` : 'auto'}; `"
    class="expand-container">
    <div ref="contentContainer">
      <slot ></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";


const ExpandContainer = defineComponent({
  name: "ExpandContainer",

  props: {
    /** Numbers in raw pixels only. No em/rem supported here.  */
    yPadding: { type: Number, default: 0 },

    /** Disables the expansion aspect and just fills the remaining content of the parent container. */
    fillRemaining: {
      type: Boolean, 
      default: false,
    }
  },

  data() {
    return {
      transitionTime: 0,

      initialized: false,
      hasLoaded: false,
      _lastContainerHeight: 0,
      height: 0,
    }
  },

  mounted() {
    // this.$refs.expandContainer.classList.add('x-expand-container');
    this._setupHeightObserver();

    this._recalculateContainerHeight(this.$refs.contentContainer.getBoundingClientRect());

    // race condition causes this to make it run a transition regardless unless we wait one ms
    // vue nexttick does not work here
    setTimeout(() => {
      this.hasLoaded = true;
      this.transitionTime = 0.15;
    }, 1)

  },

  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    /** Watching the height
     * TODO #2: Fix faster animation caused by shorter container height than parent height.
     *  Height should be clamped to parent initial height (close, but would be wrong)??? 
     */
    _setupHeightObserver() {
      const heightObs = new ResizeObserver(async (el) => {
        if (this.$refs.contentContainer && this.$refs.contentContainer) {
          const containerDims = this.$refs.contentContainer.getBoundingClientRect();

          if (containerDims.height != this._lastContainerHeight) {
            this._recalculateContainerHeight(containerDims);
            this.sleep();
          }
        }
      });

      heightObs.observe(this.$refs.contentContainer);

      this._heightObserver = heightObs;
    },

    /**
     * @param {DOMRect} containerDimensions 
     */
    _recalculateContainerHeight(containerDimensions) {
      if (process.env.NODE_ENV === "development") {
      }

      const containerHeight = containerDimensions.height;
      this._lastContainerHeight = containerHeight;
      this.height = this.yPadding * 2 + containerHeight;
    }
  }

});

export default ExpandContainer;

</script>

<style scoped lang="scss">

  .expand-container {
    // overflow-: ;

    transition-duration: var(--expand-container-transition-time);
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
    transition-property: all;
  }

  .content-container {
    // overflow-y: hidden;
    // max-height: 100%;
  }

</style>