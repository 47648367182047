<template>
  <div class="blocks-row-parent fill-height" style="">
    <!-- 10 per row -->

    <!-- row 1 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>

    <!-- row 2 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>
    
    <!-- row 3 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>

    <!-- row 4 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>

    <!-- row 5 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>

    <!-- row 6 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>

    <!-- row 7 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>

    <!-- row 8 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>

    <!-- row 9 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>

    <!-- row 10 -->
    <div class="blocks-row">
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
      <div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div><div class="block"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";


const HTMLBlockTransitions = defineComponent({
  // name: 'html-block-transitions',

  mounted(){
        //=============================
    //          FUNCTIONS
    //=============================
    function randRange(min, max) { 
      return Math.floor(Math.random() * (max - min) + min);
    } 

    var blocks = document.querySelectorAll('.block');
    function applyRandomBlockColors(){
      blocks.forEach((block) => {
        block.style.background = currentPalette[randRange(0, currentPalette.length)];
        // block.style['border-top-left-radius'] = randRange(0, 50) + '%';
        // block.style['border-top-right-radius'] = randRange(0, 50) + '%';
        // block.style['border-bottom-left-radius'] = randRange(0, 50) + '%';
        // block.style['border-bottom-right-radius'] = randRange(0, 50) + '%';
        // block.style['transform'] = `scale(${Math.random() * 2 + 0.5})`;
        // block.style['z-index'] = Math.floor(Math.random() * 100);
      });
    }

    async function startAnimationSequence(){
      const BEGIN_DELAY = 25;

      window.requestAnimationFrame(async () => {
        await setTimeout(() => {}, BEGIN_DELAY)
        applyRandomBlockColors();
      });

      // console.log('changed blocks') 

      setTimeout(startAnimationSequence, BLOCK_TRANSITION_DELAY_MS + BEGIN_DELAY);
    }

    //=============================
    //            MAIN
    //=============================

    const BLOCK_TRANSITION_DELAY_MS = 5000;

    const PALETTE_MIDNIGHT = 0;
    const PALETTE_FIRE = 1;
    const PALETTE_CHECKERED = 2;
    const PALETTE_RADIAL = 3;

    const PALETTES = [
      [
        'black',
        '#080808',
        '#060606',
        '#26093d',
        '#26093d',
        '#991414',
        '#991414',
        // '#FFFEFE',
      ],
      // [ // FIRE PAL
      //   '#fae100',
      //   '#FAC000',
      //   '#FF7500',
      //   '#FC6400',
      //   '#D73502',
      //   '#B62203',
      //   '#801100',
      //   // '#240000',
      // ],
      // [ //checkered
      // '#080808',
      // '#FFFEFE',
      // '#060606',
      // '#FA0202',
      // '#991414'
      // ],
      // [
      //   '#000000',
      //   '#150050',
      //   '#3F0071',
      //   '#610094',
      // ]

      // looks like a serial killer
      // [
      //   'radial-gradient(circle, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,173,69,1) 84%, rgba(252,247,69,1) 100%)',
      //   'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(252,247,69,1) 4%, rgba(252,173,69,1) 27%, rgba(253,29,29,1) 42%, rgba(70,10,139,1) 62%, rgba(24,0,51,1) 80%)',
      //   'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)',
      // ],
    ];

  //PALETTES[PALETTE_RADIAL]; 
    var currentPalette = PALETTES[Math.floor(Math.random() * PALETTES.length)];

    applyRandomBlockColors();
    console.log('test')

    window.requestAnimationFrame(() => {
      document.body.style = `--block-transition-delay: ${BLOCK_TRANSITION_DELAY_MS / 1000}s;`;

      startAnimationSequence();
    });
  },
});

export default HTMLBlockTransitions;

</script>

<style scoped>

  .block-rows-parent {
    /* position: absolute; */
    /* top: 0; */
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;

    background: black;
    overflow: hidden;
    filter: blur(40px);
  }

  .blocks-row {
    height: 10%;
    display: flex;
  }

  .block {
    position: relative;

    width: 10%;
    /* height: 10%; */
    flex-grow: 0;

    /* box-shadow: 2px 2px 10px rgb(255, 255, 255); */
    /* border: 1px solid white; */

    transition-property: box-shadow, background-color, border-radius, transform;
    transition-duration: var(--block-transition-delay);
    transition-timing-function: linear;
  }

  .hidden {
    display: none;
  }


</style>