<template>
  <svg
    viewBox="0 0 24 24"
    class="d-flex justify-center align-center"
    :style="`width: ${BASE_DIMENSIONS_PX * currentScale}px; fill: ${color}`"
    role="img">
    <!-- SVG group, kinda like a wrapper -->
    <g>
      <path
        :d="icon.path" />
      <rect width="24" height="24" fill="transparent" />
    </g>
    
    <slot style="display: none;" ref="passthroughIcon"></slot>
  </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";


/**
 * Currently only suports SVG paths. Makes nicely sized square icon.  
 * 
 * Props must be provided on mount, no reactivity supported. 
 */
const XIcon = defineComponent({

  props: {

    /**
     * How big the icon should be scaled. Default is 1.5 making the icon 36px. 
     */
    scale: {
      default: 1.5, 
      type: Number,
    },

    /**
     * Must be the path data for an SVG, looks something like this: M12,15.5A3.5, ... ,.97Z 
     */
    iconPath: {
      type: String,
      default: '',
    },

    color: {
      type: String, 
      default: 'black',
    }
  },

  mounted(){
    if(this.$refs.passthroughIcon != null) {
      const content = this.$refs.passthroughIcon.innerHTML;
      console.log(content);
    }
  },

  data(){
    const BASE_DIMENSIONS_PX = 24.0;

    const icon = {
      path: this.iconPath,
    }
    
    return {
      BASE_DIMENSIONS_PX,
      
      currentScale: this.scale,

      icon: Object.freeze(icon),
    }
  }
});

export default XIcon;

</script>