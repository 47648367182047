<template>
  <v-app 
    class="fill-height"
    :style="`--page-container-padding: ${pageContainerPadding}`"
  >
    <v-main class="fill-height">
      <app-background class="fill-height">
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <!-- <keep-alive> -->
              <component :is="Component" />
            <!-- </keep-alive>   -->
          </transition>
        </router-view>
      </app-background>
    </v-main>
  </v-app>
</template>

<script>

import AppBackground from '@/components/AppBackground.vue';
import ExpandContainer from '@/components/_generics/expand-container.vue';
import { defineComponent } from '@vue/runtime-core';

const App = defineComponent({

  inject: ['appStore'],

  components: {
    AppBackground,
    ExpandContainer
  },  

  mounted(){
  },

  data: function() {
    console.log(this)
    return {
      blockElements: [],
      currentYear: '{{currentYear}}',
    };
  },

  computed: {
    pageContainerPadding() {
      return this.appStore.state.pageContainerPadding;
    }
  },

  methods: {

  }
});

export default App;

</script>

<style lang="scss">

  @import '@/styles/main.scss';

  $transition: 0.3s;

  #content-container {
    width: 100%;
    max-width: 1220px; 

    margin: 20px;

    box-shadow: 0px 0px 10px black;
    border: 2px solid #ca0000;
  }

  #admin-container {
  }

  .transparent-border {
    overflow: hidden;

    padding: 4px;
    padding-top: 0;

    background: rgba(var(--v-theme-primary), 0.6);
    // border: 2px solid #ca0000;
  }

  #page-container { 
    display: flex;
    flex-direction: column;

    background: whitesmoke;
    padding: var(--page-container-padding);
    transition: padding $transition ease-in-out;

    border-top: none;

    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.6117647059) inset, 0px 1px 2px #000 inset;
  }

  .leftover-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    flex-grow: 1;
    overflow: hidden;

    background: white;
  }

  .leftover-shadow {
    position: absolute;
    z-index: 2;
    box-shadow: 0 0 10px black inset;

    width: 100%; 
    height: 100%;
  }

  .leftover-background {
    position: absolute;
    filter: blur(40px);
 
    background-image: url("@/assets/images/seed7056.png");
    background-size: auto 100%;

    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .leftover-inset-text {
    display: flex;
    flex-direction: column;

    position: absolute;

    z-index: 1;
    font-weight: bolder;
    font-size:20px;

    // background-color: #ffffff;
    color: white;

    text-shadow: -8px -8px 8px rgba(0, 0, 0, 0.575);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
  }


  hr {
    border-top: 0;
    border-color: #c8c8c8;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;;
  }

  .fade-enter-active {
    transition: opacity $transition ease;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  @media only screen and (max-width: $SCREEN_MEDIUM) {
    #page-container, .transparent-border {
      margin-bottom: 0;

      height: 100%;
      // min-height: 100%;
      // min-height: 100vh;
      // (probably) Fixes bug in safari where 100vh doesn't include the safari search bar
      // min-height: -webkit-fill-available;
      // min-height: stretch;
    }

    #content-container {
      display: flex;
      flex-direction: column;

      height: 100%;
      margin: 0;
      border: none;
      box-shadow: none;
    }

    #content-container > .page-container {
      flex-grow: 1;
    }

    .foreground {
      padding: 0;
    }
  }

  @media only screen and (max-width: $SCREEN_SMALL) {
    .page-container {
      padding: 10px 20px;
    }

    #logo-second-cont {
        display: block;
    }
  }

</style>
