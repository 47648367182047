import { createApp } from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import appStore from './store/app-store'

//import { loadFonts } from './plugins/webfontloader'
//loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .provide('appStore', appStore)
  .mount('#app')
