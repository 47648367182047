// Styles
import 'vuetify/styles'

import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

// Vuetify
import { createVuetify } from 'vuetify'

// Vuetify Docs: // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// THEME DOCUMENTATION: https://vuetifyjs.com/en/features/theme/
export default createVuetify({
  theme: {
    defaultTheme: 'custom',

    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    },

    // customProperties: true,
    themes: {
      custom: {
        colors:{ 
          primary: '#991414',
          secondary: '#ca0000',
          background: '#FFFFFF',
          surface: '#FFFFFF',
          'secondary-darken-1': '#018786',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        }
      },
    },
  },
})
